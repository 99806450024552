/** @jsx jsx */
import { Box, Grid, Heading, jsx, Paragraph } from "theme-ui";
import React from "react";
import Layout from "../components/layout/layout";

export type indexProps = {};
export const index: React.FC<indexProps> = ({}) => {
  return (
    <Layout>
      {Array.apply(null, Array(5)).map((val, i) => (
        <Heading key={i} as={`h${i}`}>{`H${i}`}</Heading>
      ))}

      <Paragraph>this is pargraph text</Paragraph>
      <Grid columns={6}>
        <Box backgroundColor="primary">primary colour</Box>
        <Box backgroundColor="primaryLight">primaryLight colour</Box>
        <Box backgroundColor="secondary">secondary colour</Box>
        <Box backgroundColor="background">background colour</Box>
        <Box color="text">text colour</Box>
        <Box color="muted">text colour muted</Box>
      </Grid>
    </Layout>
  );
};

export default index;
